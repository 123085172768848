<template>
  <b-container class="h-100">
    <b-row align-v="center" align-h="center" class="h-100">
      <b-col lg="6" md="8">
        <b-img src="/assets/images/textree_logo_blue.png" fluid alt="TEXTree Logo" center class="w-50 mb-5"></b-img>
        <b-form class="form-login" @submit.stop.prevent="submitLogin">
          <b-form-group id="form-login-group-email" label="Email" label-for="form-login-email">
            <b-form-input id="form-login-email" type="email" label="Email" v-model="email"></b-form-input>
          </b-form-group>
          <b-form-group id="form-login-group-password" label="Password" label-for="form-login-password">
            <b-form-input id="form-login-password" type="password" label="Email" v-model="password"></b-form-input>
          </b-form-group>
          <b-row align-v="center" align-h="between">
            <b-col>
              <b-button type="submit" variant="primary">Login</b-button>
            </b-col>
            <b-col>
              <b-form-text :state="loginError !== ''" class="text-right"> {{loginError}} </b-form-text>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import '@/plugins/bootstrap-vue'
import api from '@/api'
import store from '@/store'

export default {
  data: () => ({
    email: '',
    password: ''
  }),
  computed: {
    loginError: () => store.state.admin.session.error
  },
  beforeCreate () {
    store.commit('SET_ADMIN_SESSION_LOGIN_ERROR', '')
  },
  methods: {
    submitLogin: function() {
      api.master.login({
        email: this.email,
        password: this.password
      })
    }
  }
}
</script>

<style scoped>
form input {
  display: block;
  width: 100%;
}
div.actions {
  text-align: right;
  width: 100%;
  margin: 1em .5em;
}
</style>
<style>
  #plusfriend-chat-button {
    display: none;
  }
</style>
